import React, { useState } from 'react';
import { Modal, Backdrop, Fade, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button, IconButton } from '@mui/material';
//import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
//import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

function DataTable({ data }) {
    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleOpen = (record) => {
        setSelectedData(record);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const adminUser = [
        { id: 1, name: "Jane Doe" },
        { id: 2, name: "John Doe" },
        { id: 3, name: "Max Doe" },
        { id: 4, name: "Mitch Doe" },
        { id: 5, name: "Megan Doe" },
        { id: 6, name: "Molly Doe" },
    ];

    return (
        <div className="">
            <h1 className='text-2xl font-bold mb-6'>Submissions</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((record, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{record.create_at}</TableCell>
                                <TableCell>{record.fname}</TableCell>
                                <TableCell>{record.lname}</TableCell>
                                <TableCell>{record.phone}</TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>

                                    <IconButton onClick={() => handleOpen(record)}>
                                        <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                        {/*<IconButton>
                                        <DeleteForeverOutlinedIcon />
                        </IconButton>*/}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {selectedData && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <Fade in={open}>
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded shadow-xl w-full max-w-lg overflow-y-auto">
                            <div className='flex justify-between items-center mb-4'>
                                <h3 className="text-2xl">Details for {selectedData.fname} {selectedData.lname}</h3>
                                {selectedData.create_at}
                            </div>
                            {/*<pre>{JSON.stringify(selectedData, null, 2)}</pre>*/}



                            <div className='grid grid-cols-2 overflow-y-auto' style={{maxHeight: '80vh'}}>
                                <div>
                                    <label className='block mb-1 text-xs'>คำนำหน้า</label>
                                    <div className='mb-4'>
                                        {selectedData.nameTitle}
                                    </div>
                                </div>
                                <div>
                                    <label className='block mb-1 text-xs'>ชื่อ</label>
                                    <div className='mb-4'>
                                        {selectedData.fname}
                                    </div>
                                </div>
                                <div>
                                    <label className='block mb-1 text-xs'>นามสกุล</label>
                                    <div className='mb-4'>
                                        {selectedData.lname}
                                    </div>
                                </div>

                                <div>
                                    <label className='block mb-1 text-xs'>เบอร์โทรศัพท์</label>
                                    <div className='mb-4'>
                                        {selectedData.phone}
                                    </div>
                                </div>



                                <div className='col-span-2'>
                                    <label className='block mb-1 text-xs'>ที่อยู่</label>
                                    <div className='mb-4'>
                                        {selectedData.tambon}, {selectedData.amphoe} {selectedData.province}
                                    </div>
                                </div>

                                <div className=''>
                                    <label className='block mb-1 text-xs'>อายุ</label>
                                    <div className='mb-4'>
                                        {selectedData.ageRange}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>อาชีพ</label>
                                    <div className='mb-4'>
                                        {selectedData.cereer}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>รายได้บุคคลต่อเดือน</label>
                                    <div className='mb-4'>
                                        {selectedData.income}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>ลักษณะที่อยู่อาศัยปัจจุบัน</label>
                                    <div className='mb-4'>
                                        {selectedData.propertyType}
                                    </div>
                                </div>

                                <div className='col-span-2'>
                                    <label className='block mb-1 text-xs'>วัตถุประสงค์หลักที่ใช้ในการพิจารณาเลือกซื้อ</label>
                                    <div className='mb-4'>
                                        {selectedData.objective.map((item, idx) => (
                                            <span key={idx}>{idx > 0 ? ', ' : ''}{item}</span>
                                        ))
                                        }
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>งบประมาณในการเลือกซื้อที่อยู่อาศัย</label>
                                    <div className='mb-4'>
                                        {
                                            //Number format
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'THB' }).format(selectedData.budget)
                                        }
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>ขนาดพื้นที่ใช้สอยที่ท่านต้องการ</label>
                                    <div className='mb-4'>
                                        {selectedData.usableArea.map((item, idx) => (
                                            <span key={idx}>{idx > 0 ? ', ' : ''}{item}</span>
                                        ))
                                        }
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>จำนวนที่จอดรถที่ต้องการ</label>
                                    <div className='mb-4'>
                                        {selectedData.carPark}
                                    </div>
                                </div>

                                <div className='col-span-2'>
                                    <h3 className='text-lg mb-4 mt-8'>ท่านรู้จักโครงการจากที่ไหน</h3>
                                </div>


                                <div className=''>
                                    <label className='block mb-1 text-xs'>สื่อโฆษณาออฟไลน์</label>
                                    <div className='mb-4'>
                                        {selectedData.offLineMedia.map((item, idx) => (
                                            <span key={idx}>{idx > 0 ? ', ' : ''}{item}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>สื่อโฆษณาออนไลน์ </label>
                                    <div className='mb-4'>
                                        {selectedData.onlineMedia.map((item, idx) => (
                                            <span key={idx}>{idx > 0 ? ', ' : ''}{item}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>เพจหรือเว็บไซต์อื่นๆ </label>
                                    <div className='mb-4'>
                                        {selectedData.otherMedia}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='block mb-1 text-xs'>ปัจจัยที่ทำให้ท่านตัดสินใจเลือกซื้อที่อยู่อาศัย</label>
                                    <div className='mb-4'>
                                        {selectedData.whyChooseUs.map((item, idx) => (
                                            <span key={idx}>{idx > 0 ? ', ' : ''}{item}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>


                            <div className='flex justify-end gap-4'>
                                <Button variant="outline" onClick={handleClose}>Close</Button>
                                {/*<Button variant="contained" color="primary">Convert to Opportunity</Button>*/}
                            </div>
                        </div>
                    </Fade>
                </Modal>
            )}
        </div>
    );
}

export default DataTable;
