import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PrivateRoute from "./hooks/useAuth";
import Submissions from "./pages/submissions";
import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";

const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans Thai Variable"].join(","),
  },
  palette: {
    primary: {
      light: "#6e5ad5",
      main: "#3730a3",
      dark: "#000973",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route index element={<PrivateRoute> <Submissions /> </PrivateRoute>} />
          <Route path="/auth" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
export default App;