import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';

const Layout = ({ children, mainClass }) => {

    return (
        <div className={`flex flex-col min-h-screen`}>

            <div className='flex flex-auto min-w-0'>
                <Sidebar />
                <div className='w-full'>
                    <Header />
                    <main className={`p-8 pb-0 ${mainClass ? mainClass : mainClass}`}>{children}</main>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Layout;