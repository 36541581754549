import { useState, useEffect } from 'react';
import DataTable from '../components/DataTable';
import Layout from "../layouts/layout";
import { Pagination } from '@mui/material';
import axios from 'axios';

export default function Submissions() {
    const [allPage, setAllPage] = useState(0)
    const [data, setData] = useState([]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/submissions/1`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('admin_token')
            },
        }).then(function (response) {
            setData(response.data.submissions);
            setAllPage(response.data.pagination.totalPages);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    const handlePagination = (page) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/submissions/${page}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('admin_token')
            },
        }).then(function (response) {
            setData(response.data.submissions);
        }).catch(function (error) {
            console.log(error);
        });
    }


    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>

            {data?.length > 0 && (
                <DataTable data={data} />
            )}


            <div className='flex justify-between mt-6'>
                <div className='text-gray-500'>
                    
                </div>
                <Pagination
                    count={allPage}
                    onChange={(e, page) => handlePagination(page)}
                />
            </div>
        </Layout>
    );
}