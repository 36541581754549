import { NavLink } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
//import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
//import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
//import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
//import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
//import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
//import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
//import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';
//import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';

export default function Sidebar() {
    const liClass = 'flex space-x-4 items-center py-2.5 px-7 font-normal hover:text-blue hover:stroke-blue hover:border-l-4 ease-in-out duration-100 hover:bg-gray-smoke';
    const iconColor = '#595c60';
    return (
        <aside className={'h-screen main-side-bar flex flex-col justify-between side-nav side-nav-transparent side-nav-expand border-r border-slate-150 lg:w-64 2xl:w-80 sticky top-0 collapsed'}>
            <div className='' >
                <div className="side-nav-header border-b border-slate-150">
                    <div className="logo px-4 py-3">
                        <div className='text-2xl font-bold text-center'>A</div>
                    </div>
                </div>
                <div className="side-nav-content py-4 font-bold text-gray-dark" style={{ height: 'calc(100vh - 160px)'}}>
                    <ul className='flex flex-col justify-center  h-full'>
                         {/* <li className='main-page-home'>
                            <NavLink to="/" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <DashboardOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    แดชบอร์ด
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/questionnaires" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <FeaturedPlayListOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    แบบสอบถาม
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/submissions" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <ArticleOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    submissions
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/reports/customer-visits-from-survey" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <ShowChartOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายงาน
                                </span>
                            </NavLink>
                        </li>
                      
                        <li>
                            <NavLink to="/order" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <RequestPageOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    คำสั่งซื้อ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/customer" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <PeopleAltOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ลูกค้า
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/inquiry" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <Person2OutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ผู้สนใจ
                                </span>
                            </NavLink>
                        </li>
                        {/*<li>
                            <NavLink to="/report" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <ShowChartOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายงาน
                                </span>
                            </NavLink>
                        </li>*/}
                    </ul>

                    {/*
                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>สินค้า</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/product" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <Inventory2OutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายการสินค้า
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/lead-groups" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <FolderCopyOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    หมวดหมู่สินค้า
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/customer" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <BrandingWatermarkOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    แบรนด์
                                </span>
                            </NavLink>
                        </li>

                    </ul>

                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>การขาย</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/quotations" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <RequestPageOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    คำสั่งซื้อ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/invoice" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <PeopleAltOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ลูกค้า
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/report" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <ShowChartOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายงาน
                                </span>
                            </NavLink>
                        </li>
                    </ul>






                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>พนักงาน</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/user" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <PermIdentityOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    พนักงาน
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                    */}


                </div>
            </div>


            <div>
                <ul className='mb-2'>
                    <li className='main-page-home'>
                        <NavLink to="/logout" className={liClass}>
                            <span className='menu-svg-warp'>
                                <LogoutOutlinedIcon sx={{ color: iconColor }} />
                            </span>
                            <span className='menu-link-warp'>
                                ออกจากระบบ
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </aside>
    )
}